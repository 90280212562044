.searchbar {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
}

.searchbar img {
  width: 30px;
  height: auto;
}

.searchbar-title {
  display: flex;
  padding-top: 1px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.searchbar-dropdown {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 0px;
  border-radius: 0px 0px 6px 6px;
  border: 2px solid var(--gray-7);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--pure-white);
  border-top: none
}