/* Styles for the tab header container */
.tab-headers {
  display: flex; /* Align tabs in a row */
  border-bottom: 2px solid var(--gray-2); /* Bottom border for the whole tab header */
  padding: 0; /* Adjust padding as needed */
  width: 80%;
  align-self: stretch;
  margin-left: 10%;
  margin-right: 10%;
}

/* Styles for each tab/label */
.tab-header-label {
  flex: 1; /* Distribute space evenly among tabs */
  text-align: center; /* Center tab labels */
  list-style: none; /* Remove list style if it's applicable */
  padding: 10px; /* Add some padding for better readability */
  cursor: pointer; /* Change mouse cursor to pointer */
  border-right: 1px solid #ccc; /* Add a border to the right side of each tab */
  background-color: var(--gray-1); /* Light background for tabs */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

/* Remove the right border from the last tab */
.tab-header-label:last-child {
  border-right: none;
}

/* Hover effect for tabs */
.tab-header-label:hover {
  background-color: #e2e2e2; /* Darker background on hover */
}

/* Styles for the active tab */
.tab-header-label.active-label {
  background-color: var(--gray-2); /* Active tab has a different background to stand out */
  position: relative; /* Needed to correctly position the active tab over the border */
  top: 1px; /* Move the active tab slightly down to cover the bottom border */
  font-weight: bold; /* Optional: Make the active tab's label bold */
}


.form-contents.add-student {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-self: stretch;
}