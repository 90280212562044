.book-card {
  display: flex;
  padding: 12px 12px 12px 12px;
  flex-direction: row;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 12px;
  background: var(--pure-white);
  max-height: 60vh;
  /* width: 48%; */
  min-height: 30vh;
  height: 290px;
  /* max-width: min(48%, 800px); */
  overflow-y: auto;
  border: 2px solid var(--gray-7);
  box-shadow: 0px 5px 10px 0px rgba(82, 75, 73, 0.20);
  align-self: stretch;
}

.book-card:hover {
  cursor: pointer;
  background: var(--gray-1);
}

.book-card-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  gap: 10px;
}

.book-card-metadata {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.book-card .action-button {
  width: fit-content;
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .book-card {
    min-height: unset;
    height: fit-content;
  }

  .book-card-details {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
}