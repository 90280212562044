/* Fireworks Button */
.fireworks-button {
    padding: 12px 24px;
    font-size: 16px;
    background: #ff9800;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
  }
  
  .fireworks-button:hover {
    background: #e68900;
  }
  
  /* 🎆 Firework Container */
  .firework-container {
    position: fixed;
    width: 10px;
    height: 10px;
    background-color: transparent;
    pointer-events: none;
    transform-origin: center;
  }
  
  /* 🎇 Firework Particles */
  .firework-particle {
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 50%;
    opacity: 1;
    animation: explode-particle linear forwards;
  }
  
  @keyframes explode-particle {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: translate(
        calc(var(--velocity) * cos(var(--angle))),
        calc(var(--velocity) * sin(var(--angle)))
      );
      opacity: 0;
    }
  }
  
  /* 💨 Smoke Trail Effect */
  .firework-container::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 10%, transparent);
    border-radius: 50%;
    opacity: 0;
    animation: smoke-trail 1.2s ease-out forwards;
  }
  
  @keyframes smoke-trail {
    0% {
      opacity: 1;
      transform: scale(0.5) translateY(10px);
    }
    100% {
      opacity: 0;
      transform: scale(1.2) translateY(-50px);
    }
  }
  