.sidebar-container {
  position: relative;
  display: flex;
  align-self: stretch;
}

.app-sidebar {
  background-color: var(--gray-1);
  border: 2px solid var(--gray-3);
  padding: 20px;
  min-width: min(var(--app-sidebar-width), 95vw);
  width: var(--app-sidebar-width);
  max-width: var(--app-sidebar-width);
  border-radius: 24px;
  justify-content: space-between;
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  flex: 1 1 0;
  align-self: stretch; 
  min-height: var(--app-page-content-height);
  height: var(--app-page-content-height);
  max-height: var(--app-page-content-height);
  transition: all ease 1s;
}

.app-sidebar.collapsed {
  width: 0px; /* Shrinks to 0 width */
  max-width: 0px;
  min-width: 0px;
  opacity: 0; /* Fades out */
  pointer-events: none; /* Prevents interaction when collapsed */
  overflow: hidden;
  padding: 0px;
  border: none;
}

.sidebar-container .expand-button {
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  width: 15px;
  height: 40px;
  border: none;
  border-radius: 0px 6px 6px 0px;
  background-color: var(--gray-3);
  color: var(--gray-10);
  z-index: 50;
}

.sidebar-container .expand-button:hover {
  cursor: pointer;
}

.app-sidebar .col {
  gap: 20px;
  align-self: stretch;
  overflow: hidden;
}

.app-sidebar .app-button {
  align-self: stretch;
}

.app-sidebar h1 {
  color: var(--dark-green);
  text-align: center;
}

.app-sidebar hr {
  width: 250px;
  height: 2px;
  background: var(--gray-3);
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 28px;
  font-size: 14px;
  line-height: 150%; /* 21px */
  letter-spacing: 0.56px;
  align-self: stretch;
  overflow-y: auto;
}

.sidebar-content b {
  color: var(--gray-10);
  font-size: 22px;
  line-height: 100%; /* 22px */
  letter-spacing: 0.88px;
}

.sidebar-content-data {
  display: flex;
  flex-direction: column;
  padding: 5px;
  padding-bottom: 28px;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-bottom: solid 1px var(--gray-3);
}

.sidebar-content-label {
  display: flex;
  padding-top: 1px;
  align-items: center;
  gap: 10px;
  align-self: stretch; 
}

.sidebar-text {
  display: flex;
  padding-top: 1px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.sidebar-text label {
  flex: 1 0 0;
}

/* FOR MOBILE */
@media screen and (max-width: 700px) {
  .sidebar-container {
    position: absolute;
    width: fit-content;
  }

  .app-sidebar {
    width: 300px;
    padding: 5px 5px;
    min-height: var(--app-page-content-height-small);
    height: var(--app-page-content-height-small);
    max-height: var(--app-page-content-height-small);
    z-index: 99;
  }
}