.book-catalog-row {
  width: 100%;
  max-width: 100%;
  flex: 1 1 auto;
  padding-left: max(2%, 30px);
  padding-right: max(2%, 30px);
  align-items: flex-start;
  gap: min(2%, 30px);
  transition: all 0.5s ease-in-out;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Dynamic columns */
  height: fit-content; 
  min-height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
}

/* FOR MOBILE */
@media screen and (max-width: 1000px) {
  .book-catalog-row {
    grid-template-columns: 1fr;
  }
}