.question-editor {
  display: flex;
  padding: 12px 12px 35px 12px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  border-radius: 12px;
  background: var(--gray-1);
  min-height: 80vh;
  min-width: 80vw;
  max-width: 80vw;
  max-height: 80vh;
  overflow-y: scroll;
}

.multiple-choice-options-default {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
}

.multiple-choice-options-visual {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
}

.multiple-choice-option {
  display: inline-flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  width: 100%;
  align-self: center;
  justify-content: space-between;
  flex: 1 1 0;
  max-width: 100%;
}

.multiple-choice-option input[type='text'] {
  flex-grow: 1;
}

.multiple-choice-option textarea {
  flex: 1 1 0;
  display: flex;
  width: 90%;
}

.multiple-choice-option input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px; 
  height: 20px; 
  background-color: var(--pure-white); 
  border-radius: 10px; 
  border: 2px solid var(--gray-7); 
  cursor: pointer;
  
}

.multiple-choice-img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  padding: 6px;
  border-radius: 6px;
  border: 2px solid var(--gray-7);
  display: flex;
  justify-content: center;
  background-color: var(--gray-2);
}

.multiple-choice-img img {
  width: 80%;
  height: auto;
}
