.graph-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 25px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid var(--gray-2);
  background: var(--gray-1);
  width: 100%;
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
  position: relative;
}

.graph-container .labeled-input {
  align-self: unset;
  width: 40%;
  min-width: 180px;
  max-width: 180px;
  max-height: 50px;
  align-items: center;
}

.graph-container .labeled-input input {
  align-self: unset;
  max-width: 100px;
}

.graph-container .labeled-input label {
  width: 40%;
  min-width: 70px;
}

.bar-chart {
  display: flex;
  align-self: stretch;
  width: calc(100% - 20px);
  height: auto;
  overflow: auto;
}

.missed-words-table-container table th,td {
  border-collapse: collapse;
  width: fit-content;
  height: fit-content;
  table-layout: auto;
  font-size: 20px;
}

.missed-words-table {
  border-collapse: collapse;
  width: 100%;
  /* height: 100%; */
  border-radius: 10px;
  table-layout: fixed;
  font-size: 20px;
  align-self: flex-start;
  position: absolute;
  top: 0;
  left: 0;
}

.missed-words-table tr {
  border-bottom: 1px solid var(--gray-3);
}

.missed-words-table tbody {
  overflow-y: auto;
}

.missed-words-table tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.missed-words-table tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.missed-words-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.missed-words-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.missed-words-table td {
  box-shadow: 0 0 0 1pt var(--gray-2);
  background: var(--gray-2);
}

.missed-words-table th {
  box-shadow: 0 0 0 1pt var(--gray-2);
  background-color: var(--pure-white);
}

.missed-words-table th, td {
  display: auto;
  text-align: center;
  padding: 8px;
  font-size: 32px;
  height: 100%;
  width: fit-content;
  max-width: fit-content;
}

/* .missed-words-table thead {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
} */

/* .missed-words-table tbody {
  position: relative;
  min-width: 100%;
  overflow-y: auto;
  margin-top: 50px;
}  */

.graph-container .student-insights {
  border-collapse: collapse;
  width: 100%;
  /* height: 100%; */
  border-radius: 10px;
  table-layout: fixed;
  font-size: 20px;
  align-self: flex-start;
  position: absolute;
  top: 0;
  left: 0;  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.student-insights .missed-words-table {
  top: unset;
  position: unset;
}

.graph-container .tab-headers {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff; /* or your preferred background to mask content underneath */
  display: flex;
  justify-content: space-around; /* or space-between or center, as you prefer */
  /* padding: 0.5rem 0; */
  border-bottom: 1px solid #ddd; /* optional, for separation */
  z-index: 10; /* make sure it sits on top of other content */
  margin-left: unset;
  margin-right: unset;
}