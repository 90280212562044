.confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  opacity: 0.8;
  border-radius: 50%;
  animation: fall 2s linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}

/* Fade-in animation for the celebration image
.celebration-image {
  opacity: 0;
  transform: scale(0.5);
  animation: fadeInScale 6s ease-in-out forwards;
  z-index: 5;
  width: 250px;
} */

/* 🌀 Celebration Image Animation */
.celebration-image {
  opacity: 0;
  /* transform: scale(0.5) rotate(0deg); */
  animation: spinExpandSlowDown 2s cubic-bezier(0.17, 0.67, 0.83, 0.67) forwards;
  z-index: 5;
  width: 250px;
}

@keyframes spinExpandSlowDown {
  0% {
    opacity: 0;
    transform: scale(0.3) rotate(0deg);
  }
  20% {
    opacity: 1;
    transform: scale(1.1) rotate(2880deg); /* Fast spin */
  }
  40% {
    opacity: 1;
    transform: scale(1.4) rotate(5400deg); /* Slower spin */
  }
  60% {
    opacity: 1;
    transform: scale(1.5) rotate(7200deg); /* Slowing down more */
  }
  80% {
    opacity: 1;
    transform: scale(1.3) rotate(7920deg); /* Almost stopped */
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(8280deg); /* Stops at final position */
  }
}
